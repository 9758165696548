import vision from '../../images/About/vision.png';

export default function Vision() {
  return (
    <div className=''>
        <div className="container">
          <div className="row g-0 py-5">
            <div className="order-2 order-lg-8 col-lg-8 px-5">
              <h4 className="text-primary mb-4">Our Vision</h4>
              <p className="text-dark mb-3">
                Aspires to be the preferred environmental consulting firm, with
                its experience based on the implementation of environmental
                principles and standards, and guided by core values of teamwork,
                integrity, and innovation.
              </p>
            </div>
            <div className="order-1 order-lg-4 col-lg-4">
              <div className="h-100 d-flex flex-column justify-content-center">
                <div className="d-flex">
                  <div
                    className="d-flex align-items-center justify-content-center mx-auto mb-4"
                  >
                    <img src={vision} alt="vision" width="200px"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
}
