import { NavLink } from "react-router-dom";

export default function PageHeader(props){
    return (
        <div className="container-fluid bg-dark p-5">
            <div className="row">
                <div className="col-12 text-center">
                    <h1 className="display-4 text-white pt-5">{props.heading}</h1>
                    <NavLink to="/">Home</NavLink>
                    <i className="far fa-square text-primary px-2"></i>
                    <NavLink href="">{props.page}</NavLink>
                </div>
            </div>
        </div>
    )
}