import React from "react";

export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <>
      <div className="container-fluid bg-dark text-secondary p-5">
        <div className="row">
          <div className="col-lg-3 col-md-12 mx-auto mb-4">
            <h3 className="text-white mb-4">Quick Links</h3>
            <div className="d-flex flex-column">
              <a className="text-secondary mb-2" href="/">
                <i className="bi bi-arrow-right text-primary me-2"></i>Home
              </a>
              <a className="text-secondary mb-2" href="about">
                <i className="bi bi-arrow-right text-primary me-2"></i>About Us
              </a>
              <a className="text-secondary mb-2" href="services">
                <i className="bi bi-arrow-right text-primary me-2"></i>Our
                Services
              </a>
              <a className="text-secondary mb-2" href="projects">
                <i className="bi bi-arrow-right text-primary me-2"></i>Our
                Projects
              </a>
              <a className="text-secondary" href="contact">
                <i className="bi bi-arrow-right text-primary me-2"></i>Contact
                Us
              </a>
            </div>
          </div>
          <div className="col-lg-4 col-md-12 pe-2 me-4 mb-4">
            <h3 className="text-white mb-4">Get In Touch</h3>
            <p className="mb-2">
              <i className="bi bi-geo-alt text-primary me-2"></i>#84 Cantonments
              Road, Adwoa Adjeiwaa Building, Osu, Accra, GA-035-7484
            </p>
            <p className="mb-2">
              <i className="bi bi-envelope-open text-primary me-2"></i>
              info@pristine-environmentalconsultancy.com
            </p>
            <p className="mb-2">
              <i className="bi bi-envelope-open text-primary me-2"></i>
              pristineenvironmentconsultancy@gmail.com
            </p>
            <p className="mb-2">
              <i className="bi bi-telephone text-primary me-2"></i>+233 (0)50
              733 5400
            </p>
            <p className="mb-0">
              <i className="bi bi-whatsapp text-primary me-2"></i>
              <a
                className="text-primary"
                href="https://wa.me/+233507335400"
                target="_blank"
                rel="noreferrer"
              >
                +233 50 733 5400
              </a>
            </p>
          </div>
          <div className="col-lg-3 col-md-12 mb-4">
            <h3 className="text-white mb-4">Follow Us</h3>
            <div className="d-flex">
              <a
                className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                href="https://twitter.com/pristinegh_emc"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-twitter fw-normal"></i>
              </a>
              <a
                className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                href="https://facebook.com/PristineEnvironmentalManagementConsults"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-facebook-f fw-normal"></i>
              </a>
              <a
                className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2"
                href="https://www.linkedin.com/company/pristine-environmental-management-consults/"
                target="_blank"
                rel="noreferrer"
              >
                <i className="fab fa-linkedin-in fw-normal"></i>
              </a>
              {/* <a
                className="btn btn-lg btn-primary btn-lg-square rounded-circle"
                href="#"
              >
                <i className="fab fa-instagram fw-normal"></i>
              </a> */}
            </div>
          </div>
        </div>
      </div>
      <div
        className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5"
        style={{ borderColor: "rgba(256, 256, 256, 0.1) !important" }}
      >
        <p className="m-0">
          &copy; {year} {}
          Pristine Environmental Management Consults . All Rights Reserved.
          Designed by {}
          <a
            className="text-secondary border-bottom"
            href="https://htmlcodex.com"
            target="_blank"
            rel="noreferrer"
          >
            HTML Codex
          </a>
        </p>
      </div>
    </>
  );
}
