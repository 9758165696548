export default function ServicesSection() {
  return (
    <div className="container-fluid pt-6 px-5">
      <div className="container">
        <div className="text-center mx-auto mb-5">
          <h1 className="display-5 mb-0">Our Services</h1>
          <hr className="w-25 mx-auto bg-primary" />
        </div>
        <div className="row g-5">
          <div className="col-lg-4 col-md-6" data-aos="fade-up">
            <div className="service-item bg-secondary px-3">
              <h3 className="mb-3">Environmental Impact Assessment (Report)</h3>
              <p className="mb-3 text-dark">
                Pristine consult is well equipped to conduct and complete both
                scoping report and environmental impact statement report and
                submit to the regulator for the client permit.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item bg-secondary px-3" data-aos="fade-up">
              <h3 className="mb-3">Environmental Management Plan (Report)</h3>
              <p className="mb-3 text-dark">
                Pristine environmental consult offers services in the
                preparation of quality report on Environmental Management Plans. It describes how an
                activity may impact on an environment and how to mitigate the
                impact.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item bg-secondary px-3" data-aos="fade-up">
              <h3 className="mb-3">Waste Management Plan (Report)</h3>
              <p className="mb-3 text-dark">
                A Waste Management Plan (WMP) is crucial for sustainable waste
                management. It ensures effective procedures are implemented for handling,
                storage, transportation, and disposal of waste.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item bg-secondary px-3" data-aos="fade-up">
              <h3 className="mb-3">Preliminary Environmental Report</h3>
              <p className="mb-3 text-dark">
                This schedule report is primarily for projects with minor
                environmental impacts; however, this report is also used to
                analyze and identify impacts associated with proposed
                development.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item bg-secondary px-3" data-aos="fade-up">
              <h3 className="mb-3">Environmental Protection Agency Permit</h3>
              <p className="mb-3 text-dark">
                Pristine environmental consult is willing to undertake all
                consulting on its clients’ behalf, from the purchase of a form
                to the preparation of a report to the acquisition of an
                environmental permit.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="service-item bg-secondary px-3" data-aos="fade-up">
              <h3 className="mb-3">Corporate Environmental Policy (CEP)</h3>
              <p className="mb-3 text-dark">
                Corporate Environmental Policies are created for oil companies
                seeking an environmental license to operate. These policies
                apply to Oil Marketing Companies, Bulk Distribution Companies and
                Oil Trading Companies.
              </p>
              {/* <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4">
                <a
                  href="services"
                  className="btn btn-primary py-md-3 px-md-5 rounded-pill"
                >
                  Read more
                </a>
              </div> */}
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center text-white mx-auto mb-4">
            <a
              href="services"
              className="btn btn-primary py-md-3 px-md-5 rounded-pill"
            >
              Read more
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
