/* eslint-disable jsx-a11y/anchor-is-valid */
// import { NavLink } from "react-router-dom";
import img1 from "../../images/Home/1.jpg";
import cleaning from "../../images/Home/cleaning.jpg";
import img3 from "../../images/Home/3.jpg";
import AboutSection from "./AboutSection";
import ClientsSection from "./ClientsSection";
import ProjectsSection from "./ProjectsSection";
import ServicesSection from "./ServicesSection";

export default function Home() {
  document.title = "Home - Pristine Environmental Management Consult";
  return (
    <>
      <div className="container-fluid p-0">
        <div
          id="header-carousel"
          className="carousel slide carousel-fade"
          data-bs-ride="carousel"
          data-aos="zoom-out"
        >
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img className="w-100" src={img1} alt="Image1" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ "max-width": "900px" }}>
                  <h5 className="text-white text-uppercase">
                    Environmental Consultancy
                  </h5>
                  <h1 className="display-1 text-white mb-md-4">
                    Get the Edge with Our Expert Report Writing Services
                  </h1>
                  <a
                    href="contact"
                    className="btn btn-secondary py-md-3 px-md-5 rounded-pill"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={cleaning} alt="Image2" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ "max-width": "900px" }}>
                  <h5 className="text-white text-uppercase">Cleaning Services</h5>
                  <h1 className="display-1 text-white mb-md-4">
                    Reliable, Affordable and Professional Cleaning Service With
                    A Smile
                  </h1>
                  <a
                    href="contact"
                    className="btn btn-secondary py-md-3 px-md-5 rounded-pill"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <img className="w-100" src={img3} alt="Image3" />
              <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                <div className="p-3" style={{ "max-width": "900px" }}>
                  <h5 className="text-white text-uppercase">EPA Permit</h5>
                  <h1 className="display-1 text-white mb-md-4">
                    EPA Permitting Made Easy
                  </h1>
                  <a
                    href="contact"
                    className="btn btn-secondary py-md-3 px-md-5 rounded-pill"
                  >
                    Contact Us
                  </a>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      {/* <!-- Carousel End --> */}

        {/* <!-- About Start --> */}
        <AboutSection />
        {/* <!-- About End --> */}

        {/* <!-- Services Start --> */}
        <ServicesSection />
        {/* <!-- Services End --> */}

        {/* <!-- Projects Start --> */}
        <ProjectsSection />
        {/* <!-- Projects End --> */}

        {/* <!-- Clients Start --> */}
        <ClientsSection />
        {/* <!-- Clients End --> */}
      </div>
    </>
  );
}
