import logo from "../../images/logo.jpg";

export default function AboutSection() {
  return (
    <div className="container-fluid bg-secondary p-0">
      <div className="container py-5" data-aos="zoom-in">
        <div className="row g-0">
          <div className="col-lg-8 pb-4 px-5">
            <h1 className="display-5 mb-4">
              About <span className="text-primary">Pristine</span>
            </h1>
            <h4 className="text-primary mb-4">Your Solid Partner In Environment</h4>
            <p className="text-dark">
              We are an environmental consulting firm bridging standards and
              industry with our experience founded on the concepts and
              application of environmental principles and standards, guided by
              core values of teamwork, integrity and innovation.
            </p>
            <a
              href="about"
              className="btn btn-primary px-md-5 rounded-pill"
            >
              Discover more
            </a>
          </div>
          <div className="col-lg-4">
            <div className="h-100 d-flex flex-column justify-content-center">
              <div className="d-flex text-white">
                <div
                  className="d-flex flex-shrink-0 align-items-center justify-content-center bg-white text-primary rounded-circle mx-auto mb-4"
                  // style={{ width: "60px", height: "60px" }}
                >
                  <img src={logo} alt="company logo" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
