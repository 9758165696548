import React from 'react'
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from '../images/site-logo.png';

export default function Header() {
  const [showOffCanvas, setShowOffCanvas] = useState(false);

  const handleOffCanvasShow = () => {
    if (showOffCanvas) {
      setShowOffCanvas(false);
    } else {
      setShowOffCanvas(true);
    }
  };

  const handleOffCanvasClose = () => {
    setShowOffCanvas(false);
  };

  return (
    <header
      className="navbar navbar-expand-lg flex-wrap navbar-light fixed-top border-bottom"
      aria-label="Main navigation"
    >
      <div className="container-fluid">
        <a href="/" className="text-dark navbar-brand">
          <img className="logo" src={logo} alt="epa logo" width={200} height={43} />
          <span className="fw-bolder"></span>
        </a>
        <button
          className="navbar-toggler p-0 border-0"
          type="button"
          id="navbarSideCollapse"
          aria-label="Toggle navigation"
          onClick={handleOffCanvasShow}
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`navbar-collapse offcanvas-collapse ${
            showOffCanvas ? "open" : ""
          }`}
        >
          <ul className="navbar-nav flex-grow ms-auto mb-2 mb-lg-0">
            <li>
              <NavLink
                className="nav-link"
                aria-current="page"
                to="/"
                onClick={handleOffCanvasClose}
                end
              >
                Home
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to="about"
                onClick={handleOffCanvasClose}
              >
                About
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to="services"
                onClick={handleOffCanvasClose}
              >
                Services
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                aria-current="page"
                to="projects"
                onClick={handleOffCanvasClose}
              >
              Projects
              </NavLink>
            </li>
            <li>
              <NavLink
                className="nav-link"
                to="contact"
                onClick={handleOffCanvasClose}
              >
              Contact
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
