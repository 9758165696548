import PageHeader from "../../components/PageHeader";
import lungi from "../../images/Projects/lungi.jpg";
import gihoc from "../../images/Projects/gihoc.jpg";
import genser from "../../images/Projects/genser.jpg";
import gs from "../../images/Projects/gs-international.jpg";
import mentco from "../../images/Projects/mentco.jpg";
import osudoku from "../../images/Projects/osudoku-hospital.jpg";
import usc from "../../images/Projects/usc.jpg";

export default function Projects() {
  document.title = "Projects - Pristine Environmental Management Consult";

  return (
    <>
      <PageHeader heading="Projects" page="Projects" />
      <div className="container-fluid px-5">
        <div className="container py-5">
          <div className="text-center mx-auto">
            <h1 className="display-5 mb-0">Explore Our Projects</h1>
            <hr className="w-25 mx-auto bg-primary" />
          </div>
        </div>
      </div>
      <div className="container-fluid bg-light py-2">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.3s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={lungi} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>Lungi Airport Project, Sierra Leone</small>
                  <h5 className="lh-base text-white mb-3">
                    Provision of Environmental Impact Assessment (EIA)
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={genser} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>Genser Energy Project, Ghana​</small>
                  <h5 className="lh-base text-white mb-3">
                    Provision of Environmental Impact Assessment (EIA)
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={gihoc} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>GIHOC Distilleries Project, Kyekyewere, Ghana</small>
                  <h5 className="lh-base text-white mb-3">
                    Undertook a Feasibility Study
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={mentco} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>MENTCO</small>
                  <h5 className="lh-base text-white mb-3">
                    {/* Undertook a Feasibility Study */}
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={gs} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>GS International</small>
                  <h5 className="lh-base text-white mb-3">
                    {/* Undertook a Feasibility Study */}
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={osudoku} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>Shai-Osudoku District Hospital</small>
                  <h5 className="lh-base text-white mb-3">
                    {/* Undertook a Feasibility Study */}
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
            <div className="col-lg-4 wow fadeIn" data-wow-delay="0.7s">
              <div className="case-item position-relative overflow-hidden rounded mb-2">
                <img className="img-fluid" src={usc} alt="" />
                <p className="case-overlay text-decoration-none">
                  <small>USC</small>
                  <h5 className="lh-base text-white mb-3">
                    {/* Undertook a Feasibility Study */}
                  </h5>
                  {/* <span className="btn btn-square btn-primary">
                    <i className="fa fa-arrow-right"></i>
                  </span> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
