//import "./servicesPage.css";
import PageHeader from "../../components/PageHeader";
import "../../styles/content-control.css";
import business from "../../images/Services/business.jpg";
import cep from "../../images/Services/cep.jpg";
import eia from "../../images/Services/EIA.jpg";
import emp from "../../images/Services/EMP.jpg";
import epa_logo from "../../images/Services/epa-logo.jpg";
import eq from "../../images/Services/eq.jpg";
import per from "../../images/Services/PER.jpg";
import cleaning from "../../images/Services/pristine-cleaning.png";
import traffic from "../../images/Services/traffic.png";
import wmp from "../../images/Services/WMP.jpg";

const ServicesPage = () => {
  document.title = "Services - Pristine Environmental Management Consult";
  return (
    <>
      <PageHeader heading="Services" page="Services" />
      <div className="container-fluid px-5">
        <div className="container py-5">
          <div className="text-center mx-auto">
            <h1 className="display-5 mb-0">What We Offer</h1>
            <hr className="w-25 mx-auto bg-primary" />
          </div>
        </div>
      </div>
      <div className="container-fluid py-3" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center">
                <div className="d-flex flex-shrink-0 align-items-center justify-content-center mx-auto">
                  <img src={eia} alt="EIA" width="100%" height={400} />
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Environmental Impact Assessment (Report)
              </h4>
              <div className="read-more" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-eia"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Pristine consult is well equipped to conduct and complete both
                  scoping report and environmental impact statement report and
                  submit to the regulator for the client permit. Environmental
                  Impact Assessment (EIA) is the process of evaluating the
                  likely environmental impacts of a proposed project or
                  development, considering socio-economic, culture and human
                  health impact. This report is a technical report, that
                  identifies possible environmental impacts of a proposed
                  activity and how those impacts can be mitigated. We offer this
                  services to projects like, mining , large constructions,
                  farming ,housing and road/railway construction which are
                  considered for this schedule.
                </p>
                <label
                  htmlFor="read-more-checkbox-eia"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="order-2 order-lg-7 col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Environmental Management Plan (Report)
              </h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-emp"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Pristine environmental consult offers services in the
                  preparation of quality report on EMPs. It describes how an
                  activity may impact on an environment and how to mitigate the
                  impact. The Environmental Management Plan (EMP) is recognized
                  as the tool that can provide the assurance that the project
                  proponent has made suitable provisions for mitigation.
                  Environmental Management Plan is very paramount to all
                  companies that want to remain in compliance with environmental
                  regulations.
                </p>
                <label
                  htmlFor="read-more-checkbox-emp"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
            <div className="order-1 order-lg-5 col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img src={emp} alt="EMP" width="100%" height={400} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img src={wmp} alt="WMP" width="100%" height={400} />
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">Waste Management Plan</h4>

              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-wmp"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  A Waste Management Plan (WMP) plays a key role in achieving
                  sustainable waste management. This report ensure effective
                  procedures are implemented for handling, storage ,
                  transportation and disposal of waste that is generated from
                  the activities on site. The Waste Management Plan (WMP)
                  addresses management of all solid and liquid refuse, including
                  hazardous and non-hazardous waste, produced as a result of
                  Project activities within the Project Area. The WMP covers the
                  construction and operational phases.The WMP aims to provide
                  guidelines on waste reduction, segregation, collection and
                  disposal practices in accordance with international best
                  practices, to avoid deterioration of the natural environment
                  and negative impacts on the health and safety of communities
                  in the Project Area. This report is mostly recommended for
                  projects such as construction of Residence (Apartment),
                  hospitals and other undertakings. Pristine consult is readily
                  available to access the undertaking and prepare the report for
                  their client.
                </p>
                <label
                  htmlFor="read-more-checkbox-wmp"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="order-2 order-lg-7 col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Preliminary Environmental Report
              </h4>

              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-per"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  This schedule report is primarily for projects with minor
                  environmental impacts; however, this report is also used to
                  analyze and identify impacts associated with proposed
                  development. The purpose of a preliminary environmental report
                  (PER) is to assess the environmental feasibility of an
                  activity, potential business, or any activity that has genuine
                  environmental consequences. This involves a qualitative
                  examination of the physical, biological, and socioeconomic
                  means, as well as an evaluation of the consequences created by
                  the business, as well as recommended mitigation methods and an
                  environmental management strategy.
                </p>
                <label
                  htmlFor="read-more-checkbox-per"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>

            <div className="order-1 order-lg-5 col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img src={per} alt="PER" width="100%" height={400} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img src={epa_logo} alt="epa logo" width="100%" height={400} />
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Environmental Protection Agency Permit
              </h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-permit"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Pristine environmental consult is willing to undertake all
                  consulting on its clients’ behalf, from the purchase of a form
                  to the preparation of a report to the acquisition of an
                  environmental permit.
                </p>
                <label
                  htmlFor="read-more-checkbox-permit"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="order-2 order-lg-7 col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Cleaning and Fumigation Services
              </h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-cleaning"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Pristine consult also offers cleaning and fumigation services
                  for homes, churches, companies, industries, and schools. The
                  EPA, Ghana has granted the firm a license to provide such
                  services to its clients at a reasonable cost.
                </p>
                <label
                  htmlFor="read-more-checkbox-cleaning"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>

            <div className="order-1 order-lg-5 col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img
                    src={cleaning}
                    alt="pristine cleaning"
                    width="100%"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img
                    src={traffic}
                    alt="traffic impact"
                    width="100%"
                    height={400}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">Traffic Impact Assessment (TIA)</h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-tia"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  This report is created to assess the impact of a development
                  on a transportation network and to identify remedies to that
                  impact. Engineers and traffic management experts from Pristine
                  Environmental Consultancy will conduct the investigation and
                  create findings for its clients. A traffic impact assessment
                  (TIA) is a technical review of traffic problems and safety
                  concerns related to a given development. The primary goal of
                  the TIA study is to determine if a certain development project
                  will have an influence on the safety and efficiency of nearby
                  roadways.
                </p>
                <label
                  htmlFor="read-more-checkbox-tia"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="order-2 order-lg-7 col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Corporate Environmental Policy (CEP)
              </h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-cep"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Corporate Environmental Policies are developed for OIL
                  COMPANIES seeking an environmental licence to operate their
                  business, such as Oil Marketing Companies (OMC), Bulk
                  Distribution Companies (BDC), Oil Trading Companies (OTC), and
                  other non-location specific enterprises. An environmental
                  policy is a written declaration, generally signed by senior
                  management, that specifies a company's goals and principles
                  for managing environmental consequences and components of its
                  activities. Although implementing one is entirely voluntary, a
                  rising number of firms are doing so. It's especially critical
                  if you already engage with or want to deal with large
                  organizations, or if you need to show to customers and other
                  stakeholders that you're serious about managing your
                  environmental impacts responsibly.
                </p>
                <label
                  htmlFor="read-more-checkbox-cep"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>

            <div className="order-1 order-lg-5 col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img
                    src={cep}
                    alt="corporate environmental policy"
                    width="100%"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img
                    src={business}
                    alt="business plan"
                    width="100%"
                    height={400}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">Business Plan</h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-plan"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  Mining and oil businesses must submit this report in order to
                  get licenses from the EPA, NPA, and the Minerals Commission.
                  Pristine has written similar reports for all firms that
                  require them.
                </p>
                <label
                  htmlFor="read-more-checkbox-plan"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-5" data-aos="fade-up">
        <div className="container">
          <div className="row g-5">
            <div className="order-2 order-lg-7 col-lg-7 px-2" id="module">
              <h4 className="text-primary mb-4">
                Environmental Quality Monitoring
              </h4>
              <div className="read-more mb-12" style={{ "--line-clamp": "3" }}>
                <input
                  id="read-more-checkbox-eq"
                  type="checkbox"
                  className="read-more__checkbox"
                  aria-hidden="true"
                ></input>
                <p className="read-more__text text-dark mb-3">
                  We also offer environmental quality monitoring services for
                  air quality, noise level, soil study, and effluent quality.
                  Pristine Environmental Consult has the personnel and equipment
                  needed to conduct readings for businesses that require
                  monitoring. Environmental monitoring is the use of instruments
                  and procedures to study an environment, assess its quality,
                  and define environmental parameters in order to precisely
                  estimate the influence of an activity on the environment. The
                  primary goal of environmental monitoring is to control and
                  limit the environmental impact of an organization's
                  operations, either to assure compliance with laws and
                  regulations or to limit risks of detrimental impacts on the
                  natural environment and preserve human health.
                </p>
                <label
                  htmlFor="read-more-checkbox-eq"
                  className="read-more__label text-primary"
                  data-read-more="Read more..."
                  data-read-less="See less"
                  aria-hidden="true"
                ></label>
              </div>
            </div>

            <div className="order-1 order-lg-5 col-lg-5">
              <div className="h-100 d-flex flex-column justify-content-center pb-4">
                <div className="d-flex flex-shrink-0 mx-auto">
                  <img
                    src={eq}
                    alt="environmental quality"
                    width="100%"
                    height={400}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicesPage;
