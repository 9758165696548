import lungiImage from "../../images/Projects/lungi.jpg";
import genserImage from "../../images/Projects/genser.jpg";
import gihocImage from "../../images/Projects/gihoc.jpg";

export default function ProjectSection() {
  return (
    <div className="container-fluid pt-6 px-5">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{ "max-width": "600px" }}>
          <h1 className="display-5 mb-0">Projects</h1>
          <hr className="w-25 mx-auto bg-primary" />
        </div>
        <div className="row g-5">
          <div className="col-lg-4 col-md-6 mb-3" data-aos="zoom-in">
            <div className="project-item text-center px-3">
              <div className="mb-3">
                <img src={lungiImage} alt="lungi airport" />
              </div>
              <div className="align-items-center mb-2">
                <h4 className="text-primary mb-2">Airport Expansion Project</h4>
                <p className="mb-4 text-dark">
                  Environmental Impact Assessment (EIA) on the Lungi Airport
                  Port Expansion Project, Sierra Leone
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6 mb-3">
            <div className="project-item text-center px-3" data-aos="zoom-in">
              <div className="mb-3">
                <img src={genserImage} alt="genser project" />
              </div>
              <div className="align-items-center mb-2">
                <h4 className="text-primary mb-2">Thermal Plant Project</h4>
                <p className="mb-4 text-dark">
                  Environmental Impact Assessment on the Genser Energy, thermal
                  plant project , Ghana
                </p>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-md-6">
            <div className="project-item text-center px-3" data-aos="zoom-in">
              <div className="mb-3">
                <img src={gihocImage} alt="gihoc project" />
              </div>
              <div className="align-items-center mb-2">
                <h4 className="text-primary mb-2">Feasibility Study Project</h4>
                <p className="mb-4 text-dark">
                  Feasibility Study on GIHOC Distilleries , fruits to alcohol
                  products, Kyekyewere, Ghana
                </p>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-center text-white mx-auto mb-4 mt-3">
            <a
              href="projects"
              className="btn btn-primary py-md-3 px-md-5 rounded-pill"
            >
              View More
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
