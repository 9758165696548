import "../../styles/slider.css";
import allied from "../../images/Home/Clients/allied.png";
import blackivy from "../../images/Home/Clients/blackivy.jpg";
import gihoc from "../../images/Home/Clients/gihoc.jpg";
import heiland from "../../images/Home/Clients/heiland.png";
import humano from "../../images/Home/Clients/Humano.png";
import koala from "../../images/Home/Clients/koala.png";
import luccahealth from "../../images/Home/Clients/luccahealth.png";
import subsea7 from "../../images/Home/Clients/subsea7.png";
import epa from "../../images/Home/Clients/epa-logo.jpg";
import sankofa from "../../images/Home/Clients/gold-coast-foods.jpg";
import goshen from "../../images/Home/Clients/goshen.jpg";
import osudoku from "../../images/Home/Clients/osudoku-hospital.jpg";
import poponya from "../../images/Home/Clients/poponya.png";
import sentuo from "../../images/Home/Clients/sentuo-logo.png";
import sod from "../../images/Home/Clients/sod-hospital.png";
import sunda from "../../images/Home/Clients/sunda.png";

export default function ClientsSection() {
  return (
    <div className="container-fluid pt-6 px-5 mb-3">
      <div className="container">
        <div className="text-center mx-auto mb-5" style={{ "max-width": "600px" }}>
          <h1 className="display-5 mb-0">Our Clients</h1>
          <hr className="w-25 mx-auto bg-primary" />
        </div>
        <div className="row">
          <div className="slider col-12">
            <div className="slide-track">
              <div className="slide">
                <img src={allied} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={blackivy} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={gihoc} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={heiland} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={humano} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={koala} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={luccahealth} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={subsea7} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={epa} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={sankofa} height="150" width="150" alt="" />
              </div>
              <div className="slide">
                <img src={goshen} height="150" width="150" alt="" />
              </div>
              {/* <div className="slide">
                <img src={sentuo} height="150" width="250" alt="" />
              </div> */}
              <div className="slide">
                <img src={sunda} height="150" width="150" alt="" />
              </div>
              {/* <div className="slide">
                <img src={sod} height="150" width="250" alt="" />
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
