export default function CoreValues() {
  return (
      <div className="container pt-6 px-5">
      <div className="text-center mx-auto mb-5" style={{ "max-width": "600px" }}>
        <h1 className="display-5 mb-0">Our Core Values</h1>
        <hr className="w-25 mx-auto bg-primary" />
      </div>
      <div className="row">
        <div className="col-lg-3 col-md-6 mb-3" data-aos="fade-up">
          <div className="value-item text-center my-3 py-3 px-3">
            {/* <div className="mb-3">
                <img src="" alt="lungi airport"/>
              </div> */}
            <div className="align-items-center mb-2">
              <h4 className="text-primary mb-2">Customer Satisfaction</h4>
              <p className="mb-4 text-dark">
                We provide our services with honesty and integrity, and we
                respect time and keep our promises with the aim of achieving the
                highest level of client satisfaction.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6 mb-3" data-aos="fade-up">
          <div className="value-item text-center my-3 py-3 px-3">
            {/* <div className="mb-3">
              <img src="" alt="genser project" />
            </div> */}
            <div className="align-items-center mb-2">
              <h4 className="text-primary mb-2">Innovation</h4>
              <p className="mb-4 text-dark">
                We are empowered to create high-quality innovative products,
                services, and processes in teams that are driven by
                collaboration and creativity.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos="fade-up">
          <div className="value-item text-center my-3 py-3 px-3">
            {/* <div className="mb-3">
              <img src="" alt="gihoc project" />
            </div> */}
            <div className="align-items-center mb-2">
              <h4 className="text-primary mb-2">Teamwork</h4>
              <p className="mb-4 text-dark">
                We value our employees and, as a result, encourage a culture of
                personal and professional development, and also a healthy
                work-life balance. We treat each other like a family.
              </p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos="fade-up">
          <div className="value-item text-center my-3 py-3 px-3">
            {/* <div className="mb-3">
              <img src="" alt="gihoc project" />
            </div> */}
            <div className="align-items-center mb-2">
              <h4 className="text-primary mb-2">Social Responsibility</h4>
              <p className="mb-4 text-dark">
                We are dedicated to helping and improving the communities in
                which we live, and work. We create products and technologies
                that make better use of our environment.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
