import logo from '../../images/logo.jpg';

export default function Profile() {
  return (
    <div className=''>
        <div className="container mb-4">
          <div className="row g-0 py-5">
            <div className="col-lg-8 px-5">
              <h1 className="display-5 mb-4">
                About <span className="text-primary">Pristine</span>
              </h1>
              <h4 className="text-primary mb-4">Our Company</h4>
              <p className="text-dark mb-3">
                Pristine Environmental Management Consult is an environmental
                consulting firm based in Ghana. It was founded in June 2017.
              </p>
              <p className="text-dark">
                The firm has been striving to provide consulting services that
                makes us a “Real Pertness” for our clients. It is our policy to
                continue to work closely with each client with a sense of
                commitment on a long-term basis to ensure that the best
                practices are integrated into its operations.
              </p>
            </div>
            <div className="col-lg-4">
              <div className="h-100 d-flex flex-column justify-content-center mb-4">
                <div className="d-flex">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle mx-auto"
                  >
                    <img src={logo} alt="company logo" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
  );
}
