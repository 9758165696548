import mission from '../../images/About/mission.png';

export default function Mission() {
  return (
    <div className=''>
        <div className="container mb-4">
          <div className="row g-0 py-5">
            <div className="col-lg-4">
              <div className="h-100 d-flex flex-column justify-content-center">
                <div className="d-flex text-white">
                  <div
                    className="d-flex flex-shrink-0 align-items-center justify-content-center mx-auto mb-4"
                  >
                    <img src={mission} alt="mission" width="200px"/>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-8 px-5">
              <h4 className="text-primary mb-4">Our Mission</h4>
              <p className="text-dark mb-3">
                To deliver valued products and services, grow the company
                environmentally, conscientiously and remain sacred to the
                culture that make both our employees and clients proud to work
                with <strong>PRISTINE ENVIRONMENTAL CONSULT</strong>.
              </p>
            </div>
          </div>
        </div>
    </div>
  );
}
