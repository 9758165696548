/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import PageHeader from "../../components/PageHeader";
import Profile from "./Profile";
import Mission from "./Mission";
import Vision from "./Vision";
import CoreValues from "./CoreValues";

export default function About() {
  document.title = "About - Pristine Environmental Management Consult";
  return (
    <>
      <PageHeader heading="About Us" page="About" />
      <div className="container-fluid px-0 py-5">
        <Profile />
        <Mission />
        <Vision />
        <CoreValues />
      </div>
    </>
  );
}
