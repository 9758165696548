import React from "react";
import "./ContactPage.css";
import PageHeader from "../../components/PageHeader";
import { GoogleMap, LoadScript, MarkerF } from "@react-google-maps/api";

export default function ContactSection() {
  document.title = "Contact - Pristine Environmental Management Consult";

  const mapHeight = window.innerHeight;

  const containerStyle = {
    height: mapHeight,
  };

  const center = {
    lat: 5.565524695057227,
    lng: -0.180808302437783,
  };

  const position = {
    lat: 5.565524695057227,
    lng: -0.180808302437783,
  };

  return (
    <>
      <PageHeader heading="Contact Us" page="Contact" />
      <section className="ContactSection section-bg">
        <div className="container mt-4" data-aos="fade-up">
          <div className="row">
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-12">
                  <div className="info-box">
                    <h3>
                      <i className="bi bi-geo-alt text-primary me-2"></i>Our
                      Address
                    </h3>
                    <p>#84 Cantonments Road</p>
                    <p>Adwoa Adjeiwaa Building</p>
                    <p>Osu, Accra</p>
                    <p>GA-035-7484</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4" style={{ height: "180px" }}>
                    <h3>
                      <i className="bi bi-envelope-open text-primary me-2"></i>
                      Email Us
                    </h3>
                    <p className="mb-2">
                      info@pristine-environmentalconsultancy.com
                    </p>
                    <p>pristineenvironmentalconsultancy@gmail.com</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="info-box mt-4" style={{ height: "180px" }}>
                    <h3>
                      <i className="bi bi-telephone text-primary me-2"></i>Call
                      Us
                    </h3>
                    <p>+233 (0)50 733 5400</p>
                    <h3>
                      <i className="bi bi-whatsapp text-primary me-2"></i>Chat
                      with us
                    </h3>
                    <a
                      className="text-primary"
                      href="https://wa.me/+233507335400"
                      target="_blank"
                      rel="noreferrer"
                    >
                      +233 50 733 5400
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 section-t8">
                <div className="contact-map box" data-aos="zoom-in">
                  <div id="map" className="mb-5">
                    <LoadScript googleMapsApiKey="AIzaSyCdRa3JPYDuwmk3QI4D1YAez-z8yir57Gk">
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={18}
                      >
                        <MarkerF position={position}></MarkerF>
                      </GoogleMap>
                    </LoadScript>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
