import { useEffect } from "react";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Outlet } from "react-router-dom";
import BackToTop from "./components/BackToTop";
import AOS from 'aos';

function App() {
  useEffect(() => {
    AOS.init({
      duration : 1500
    });
  }, []);
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
      <BackToTop />
    </>
  );
}

export default App;
